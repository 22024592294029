.login_typo {
  font-size: 35px;
  font-weight: 500;
}

.sign_in_typo {
  font-size: 16px;
  font-weight: 400
}

.error_message {
  font-weight: 400;
  font-size: 0.75rem !important;
  padding-left: 12px;
  padding-top: 5px;
  text-align: left;
  color: #ff5630;
}
