.logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
 
  width: 143.69px;
  height: 151.13px;
  padding: 20px 0;

  img {
    max-width: 236% !important;
    margin-bottom: 6rem;
    border: 1px solid rgba(243, 166, 58, 0.21);
    border-radius: 17.6935px;

  }
}
